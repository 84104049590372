<template>
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div class="person-item">
      <div class="person-item-body">
        <div class="person-item-left is-hidden-mobile">
          <img
            v-if="person.gender === 'Male'"
            src="https://mylastwill.s3.amazonaws.com/static/img/button-male.png"
            alt="male"
          />
          <img
            v-else
            src="https://mylastwill.s3.amazonaws.com/static/img/button-female.png"
            alt="female"
          />
        </div>
        <div class="person-item-right">
          <a class="person-item-title display-block">
            <h5>
              {{ person.full_name }}
            </h5>
          </a>
          <div class="dropdown-divider"></div>
          <a class="person-item-title-sub display-block">
            <h6>
              {{ person.relationship }}
              <span v-if="person.under18" class="text-table-detail">
                - under 18</span
              >
              <span v-else-if="person.under25" class="text-table-detail">
                - under 25</span
              >
            </h6>
          </a>
          <div class="person-item-roles is-hidden-mobile">
            <a
              v-if="
                person.executor_primary ||
                person.executor_secondary ||
                person.executor_sole_primary
              "
              >Executor</a
            >
            <a
              v-if="
                person.trustee_primary ||
                person.trustee_secondary ||
                person.trustee_sole_primary
              "
              >Trustee</a
            >
            <a
              v-if="
                person.guardian_primary ||
                person.guardian_secondary ||
                person.guardian_sole_primary
              "
              >Guardian</a
            >
            <a
              v-if="
                person.distribution_primary || person.distribution_sole_primary
              "
              >Beneficiary</a
            >
            <a v-if="person.pets_primary || person.pets_sole_primary">Pets</a>
          </div>

          <div class="person-details-wrapper display-flex">
            <div class="person-details-left">
              <div>Gender:</div>
              <div v-if="!person.child">Address:</div>
              <div v-else>Dob:</div>

              <div v-if="!person.child">Email:</div>

              <div v-else>Born to:</div>
            </div>
            <div class="person-details-right">
              <a>{{ person.gender }}</a>
              <a v-if="!person.child">{{
                person.address || 'None Provided'
              }}</a>
              <a v-else>
                {{
                  formatDate(
                    new Date(
                      person.dob_year,
                      Number(person.dob_month) - 1,
                      person.dob_day
                    )
                  )
                }}</a
              >
              <a v-if="!person.child">
                {{ person.email || 'Not Provided' }}
              </a>
              <a v-else>
                {{ person.child_born_to || 'Not Provided' }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="person-item-footer">
        <a>
          <button v-on:click="$emit('selectPerson', person)" type="button">
            <i aria-hidden="true" class="fa fa-user-edit person-item-line"></i>
          </button>
        </a>

        <button v-on:click="$emit('delete', person)" type="button">
          <i aria-hidden="true" class="fa fa-trash-alt person-item-line"></i>
        </button>

        <a></a>
      </div>
    </div>
  </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'PersonCard',
  mixins: [TextFormat],
  components: {},
  props: ['person']
}
</script>

<style></style>
