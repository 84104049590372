var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('div',{staticClass:"person-item"},[_c('div',{staticClass:"person-item-body"},[_c('div',{staticClass:"person-item-left is-hidden-mobile"},[(_vm.person.gender === 'Male')?_c('img',{attrs:{"src":"https://mylastwill.s3.amazonaws.com/static/img/button-male.png","alt":"male"}}):_c('img',{attrs:{"src":"https://mylastwill.s3.amazonaws.com/static/img/button-female.png","alt":"female"}})]),_c('div',{staticClass:"person-item-right"},[_c('a',{staticClass:"person-item-title display-block"},[_c('h5',[_vm._v(" "+_vm._s(_vm.person.full_name)+" ")])]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"person-item-title-sub display-block"},[_c('h6',[_vm._v(" "+_vm._s(_vm.person.relationship)+" "),(_vm.person.under18)?_c('span',{staticClass:"text-table-detail"},[_vm._v(" - under 18")]):(_vm.person.under25)?_c('span',{staticClass:"text-table-detail"},[_vm._v(" - under 25")]):_vm._e()])]),_c('div',{staticClass:"person-item-roles is-hidden-mobile"},[(
              _vm.person.executor_primary ||
              _vm.person.executor_secondary ||
              _vm.person.executor_sole_primary
            )?_c('a',[_vm._v("Executor")]):_vm._e(),(
              _vm.person.trustee_primary ||
              _vm.person.trustee_secondary ||
              _vm.person.trustee_sole_primary
            )?_c('a',[_vm._v("Trustee")]):_vm._e(),(
              _vm.person.guardian_primary ||
              _vm.person.guardian_secondary ||
              _vm.person.guardian_sole_primary
            )?_c('a',[_vm._v("Guardian")]):_vm._e(),(
              _vm.person.distribution_primary || _vm.person.distribution_sole_primary
            )?_c('a',[_vm._v("Beneficiary")]):_vm._e(),(_vm.person.pets_primary || _vm.person.pets_sole_primary)?_c('a',[_vm._v("Pets")]):_vm._e()]),_c('div',{staticClass:"person-details-wrapper display-flex"},[_c('div',{staticClass:"person-details-left"},[_c('div',[_vm._v("Gender:")]),(!_vm.person.child)?_c('div',[_vm._v("Address:")]):_c('div',[_vm._v("Dob:")]),(!_vm.person.child)?_c('div',[_vm._v("Email:")]):_c('div',[_vm._v("Born to:")])]),_c('div',{staticClass:"person-details-right"},[_c('a',[_vm._v(_vm._s(_vm.person.gender))]),(!_vm.person.child)?_c('a',[_vm._v(_vm._s(_vm.person.address || 'None Provided'))]):_c('a',[_vm._v(" "+_vm._s(_vm.formatDate( new Date( _vm.person.dob_year, Number(_vm.person.dob_month) - 1, _vm.person.dob_day ) )))]),(!_vm.person.child)?_c('a',[_vm._v(" "+_vm._s(_vm.person.email || 'Not Provided')+" ")]):_c('a',[_vm._v(" "+_vm._s(_vm.person.child_born_to || 'Not Provided')+" ")])])])])]),_c('div',{staticClass:"person-item-footer"},[_c('a',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('selectPerson', _vm.person)}}},[_c('i',{staticClass:"fa fa-user-edit person-item-line",attrs:{"aria-hidden":"true"}})])]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('delete', _vm.person)}}},[_c('i',{staticClass:"fa fa-trash-alt person-item-line",attrs:{"aria-hidden":"true"}})]),_c('a')])])])
}
var staticRenderFns = []

export { render, staticRenderFns }