<template>
  <NavQuestion
    :heading="'Your People'"
    :questionOptions="false"
    v-bind:background="'bg-people-manager'"
  >
    <template v-slot:contents>
      <PersonCard
        v-for="(person, index) in people"
        v-bind:key="index"
        v-bind:person="person"
        @selectPerson="selectPerson"
        @delete="showDeletePerson"
      ></PersonCard>
      <p v-if="people.length === 0">No people added.</p>

      <PersonModal
        v-show="showPersonModal"
        :show="showPersonModal"
        v-bind:person="selectedPerson"
        @close="closePersonModal"
      >
      </PersonModal>
      <DeleteModal
        v-show="showDeleteModal"
        @close="showDeleteModal = false"
        @confirm="deletePerson"
        v-bind:bodyText="deletePersonText"
        v-bind:loading="showDeleteProgress"
      >
      </DeleteModal>
    </template>
  </NavQuestion>
</template>
<script>
import NavQuestion from '@/common/ui/NavQuestion'
import PersonCard from './PersonCard'
import PersonModal from './PersonModal'
import DeleteModal from '@/common/components/DeleteModal'
import { http } from '@/services'

export default {
  name: 'PeoplePage',
  components: {
    NavQuestion,
    PersonCard,
    PersonModal,
    DeleteModal
  },
  computed: {
    people() {
      return this.$store.getters.people
    },
    deletePersonText() {
      var string = ''
      if (this.selectedPerson)
        string += 'Remove ' + this.selectedPerson.full_name + ' from your Will?'
      if (
        this.selectedPerson &&
        (this.selectedPerson.partner || this.selectedPerson.partner_ex)
      )
        string += ' This will set your marital status to single.'
      return string
    }
  },
  data() {
    return {
      showPersonModal: false,
      showDeleteModal: false,
      selectedPerson: null,
      showDeleteProgress: false
    }
  },
  methods: {
    closePersonModal() {
      this.selectedPerson = null
      this.showPersonModal = false
      this.showDeleteModal = false
    },
    selectPerson(person) {
      this.showPersonModal = true
      this.selectedPerson = person
    },
    async deletePerson() {
      this.showDeleteProgress = true
      http
        .delete('/wills/api/person', {
          params: { person_pk: this.selectedPerson.id }
        })
        .then((response) => {
          this.$notification.success('Person deleted')
          this.$store.commit('personDelete', this.selectedPerson.id)
          this.closePersonModal()
          this.showDeleteProgress = false
        })
        .catch((error) => {
          console.log(error)
          this.closePersonModal()
          this.showDeleteProgress = false
        })
      if (this.selectedPerson.partner || this.selectedPerson.partner) {
        await this.resetPersonalDetailsPartner()
      }
    },
    showDeletePerson(value) {
      this.selectedPerson = value
      this.showDeleteModal = true
    },
    async resetPersonalDetailsPartner() {
      this.$store.commit('personalDetails', {
        marital_status: 'Single',
        partner_full_name: '',
        partner_gender: ''
      })
      this.$store.dispatch('savePersonalDetails')
    }
  }
}
</script>

<style></style>
